<template>
  <loading
    :active="globalLoading"
  />
  <router-view />
</template>
<script>

import Loading from "vue-loading-overlay";
import { mapActions, mapMutations, mapState } from 'vuex';
import { statService, eventIds } from '@/utils/statService';
import urlHelper from '@/utils/url.helper';
import api from '@/services/api';

const GET = urlHelper.parseQueryString(location.href).GET;
if (GET.userId && GET.rand && GET.session) {
  const params = {response: GET};
  api.updateRequestParams(params, false);
}
if (GET.channel) {
  statService.init({
    channel: GET.channel
  });
}

export default {
  name: 'App',
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      globalLoading: (state) => state.globalLoading,
    }),
  },
  async mounted() {
    this.loaded();
    statService.setPostHog(this.$posthog);
    statService.logEvent(eventIds.appLoaded, 'webview_exp');
    this.setGlobalLoading(false);
    this.$store.commit('setExperimentCode', GET.experimentCode);
    this.$store.commit('setChannel', GET.channel);
  },
  methods: {
    ...mapActions({
      loaded: 'communications/sendLoadedEvent',
    }),
    ...mapMutations({
      setGlobalLoading: 'setGlobalLoading',
    })
  },
}
</script>
