import api from '@/services/api';

export default {
  namespaced: true,
  actions: {
    async saveUserData({ rootState, }, data) {
      return await api.post('/105/api/experiment-data/user-data/create', {
        experimentCode: rootState.experimentCode,
        parameterName: data.parameterName,
        parameterValue: data.parameterValue,
      });
    },

    async sendGrowthbookEventClose({ rootState }) {
      if (rootState.channel && rootState.experimentCode) {
        return await api.post(`/105/api/experiment-data/user-data/event?channel=${rootState.channel}`, {
          eventName: `${rootState.experimentCode}_cancel_experiment`
        });
      }
    },
  }
}