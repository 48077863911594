import { createStore } from 'vuex';

import communications from "@/store/communications"
import userData from '@/store/userData';

export const store = createStore({
  modules: {
    communications,
    userData,
  },
  state() {
    return {
      globalLoading: true,
      experimentCode: null,
      channel: null,
    };
  },
  mutations: {
    setGlobalLoading(state, val) {
      state.globalLoading = !!val;
    },
    setExperimentCode(state, val) {
      state.experimentCode = val;
    },
    setChannel(state, val) {
      state.channel = val;
    },
  }
});

export default store;
