import axios from 'axios';
import Cookies from "js-cookie";
import config from '@/config';

const axiosApi = axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  validateStatus(status) {
    return status >= 200 && status <= 500;
  },
});

export default {

  post(url, data = {}, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.post(url, data, config);
  },

  get(url, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.get(url, config);
  },

  getRequestParams() {
    return {
      userId: Cookies.get('requestParams.userId'),
      rand: Cookies.get('requestParams.rand'),
      session: Cookies.get('requestParams.session'),
    }
  },

  updateRequestParams(params) {
    const response = params.response;
    if (!response.userId || !response.rand || !response.session) {
      throw Error('No valid request params');
    }
    let savedUserId = Cookies.get('requestParams.userId');
    Cookies.set('requestParams.userId', response.userId);
    Cookies.set('requestParams.rand', response.rand);
    Cookies.set('requestParams.session', response.session);
    if (savedUserId != response.userId) {
      localStorage.clear();// Просто очищаем все локальные данные при смене пользователя
    }
  },

  isSuccess(response) {
    return response && response.status && response.status === 200 && "data" in response && !response.data.error;
  },

  throwError(response) {
    return response && response.data || {};
  }


}
