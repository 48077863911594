import posthog from "posthog-js";
import config from '@/config'

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthog.init(
            config.POSTHOG_API_KEY,
            {
                api_host: config.POSTHOG_API_HOST,
                person_profiles: 'identified_only',
                capture_pageview: false,
                capture_pageleave: false
            }
        );
    },
};