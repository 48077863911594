import api from "@/services/api";
import store from "@/store";

class UrlHelper {
  constructor() {
  }

  getQueryForEvents() {
    return urlHelper.parseQueryString(location.href);
  }

  getPropsForEvents() {
    return {
      source: this.getQueryForEvents().GET.source || api.SOURCE_WEB_VERSION,
      experimentId: store.state.experimentId || 'none'
    };
  }

  getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  /**
   * @description change null values to empty string
   */
  _normalizeRequestParams(data, key) {
    if (String(data) === 'undefined') {
      return;
    }
    let fieldName;
    if (data[key]) {
      for (fieldName in data[key]) {
        if (null === data[key][fieldName]) {
          data[key][fieldName] = '';
        }
      }
    }
  }

  /**
   * @description change null values to empty string
   *
   */
  normalizeRequestParams(data) {
    let fieldName;

    this._normalizeRequestParams(data, 'carFullInfo');
    this._normalizeRequestParams(data, 'car');
    this._normalizeRequestParams(data, 'insurer');
    this._normalizeRequestParams(data, 'owner');
    this._normalizeRequestParams(data, 'pts');
    this._normalizeRequestParams(data, 'sts');
    this._normalizeRequestParams(data, 'policy');

    for (fieldName in data) {
      if (null === data[fieldName]) {
        data[fieldName] = '';
      }
    }
  }

  getQueryParams() {
    const search = location.search.substring(1);
    if (search) {
      return JSON.parse('{"' + search.replace(/&/g, '","')
          .replace(/=/g, '":"') + '"}', function (key, value) {
        return key === "" ? value : decodeURIComponent(value)
      });
    }

    return {};
  }

  /**
   *  Перед тем как выпилить, посмотрите ролик https://rosfines.slack.com/archives/G01EZ0ZFGET/p1616490515008800
   *
   * @description parse http query string
   * @param {String} s
   * @return Object a-la PHP $_GET object
   */
  parseQueryString(s) {
    if (!s && window && window.location && window.location.href) {
      s = window.location.href;
    }
    let a, i, GET = {}, aB, length = 0, urlBase;
    a = s.split('#');
    a = a[0].split('?');
    urlBase = a[0];
    if (!a[1]) {
      return {'GET': {}, 'length': 0, 'urlBase': urlBase};
    }
    a = String(a[1]).split('&');
    for (i = 0; i < a.length; i++) {
      aB = a[i].split('=');
      GET[aB[0]] = aB[1];
      length++;
    }
    return {'GET': GET, 'length': length, 'urlBase': urlBase};
  }

  /**
   * @description Безопасная обработка ответа сервера redirectTo
   * @param {String} redirectTo
   * @return Object {name, query} for $router.push().
   */
   getRedirectParams(redirectTo) {
    let name = String(redirectTo),
        queryParams = {},
        queryParamsFromCurrentLocation = {},
        result = {}, i;
    queryParamsFromCurrentLocation = this.parseQueryString('');
    if (~name.indexOf('?')) {
      queryParams = this.parseQueryString(name).GET;
      name = name.split('?')[0];
    }
    for (i in queryParamsFromCurrentLocation.GET) {
      queryParams[i] = queryParamsFromCurrentLocation.GET[i];
    }

    result.name = name;
    result.query = queryParams;

    return result;
  }
}

const urlHelper = new UrlHelper();

export default urlHelper;
