import { defineRule, configure } from 'vee-validate';
import { required, email, min } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import moment from "moment";
import {FRONT_DATE_FORMAT} from '@/constants/dateFormat';

configure({
  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'Поле является обязательным к заполнению',
      promoCodeLength: 'Промокод состоит не менее, чем из 9 символов и тире',
      vehiclePlate: 'Используйте только русские буквы ЕТУОРАНКХСВМ и цифры',
      vehiclePlateLength: 'Введите госномер формата А000АА00',
      vehiclePlateNumber: 'Используйте только русские буквы ЕТУОРАНКХСВМ и цифры',
      vehiclePlateRegion: 'Используйте только от двух до трех цифр',
      stsSeriaCryllicOnly: 'Напишите серию русскими буквами',
      stsNumber: 'Неверный формат номера',
      stsDate: 'Дата не может быть меньше года выпуска',
      eptsNumber: 'Неверный формат номера',
      markValidator: 'Выберите марку автомобиля из списка',
      markModelValidator: 'Выберите модель автомобиля из списка',
      modificationValidator: 'Выберите мощность автомобиля из списка',
      productionYear: 'Выберите год из списка',
      power: 'Неверно указана мощность',
      vin: 'Некорректное значение',
      bodyNumber: 'Некорректное значение',
      diagnosticCardNumber: 'Номер должен содержать от 15 до 21 символа',
      licenceNumber: 'Некорректное значение',
      passportNumber: 'Некорректное значение',
      personName: 'Некорректное значение',
      phone: 'Неверный формат номера',
      email: 'Email введен некорректно',
      date: 'Дата введена неверно',
      datePrev: 'Дата не может быть больше текущей',
      datePrevYear: 'Дата не может быть больше текущей',
      dateNext: 'Дата не может быть меньше текущей',
      diagnosticCardDate: 'Дата не может быть меньше 01.02.2021',
      birthDate18: 'Возраст не может быть меньше 18 лет',
      licenceIssueDate: 'Права не могут быть выданы раньше начала стажа',
      experienceStartDate: 'Стаж не может начинаться раньше чем с 16 лет',
      experienceStartYear: 'Стаж не может начинаться раньше чем с 16 лет',
      passportIssueDate: 'Паспорт не могут выдать раньше чем с 14 лет',
      min2: 'Фамилия должна быть не короче двух символов',
      powerLessThan: 'Не страхуем автомобили мощнее 1000 л.с',
      driversExists: 'Нужно указать водителей или выбрать "Страховка без ограничений"',
      policyNumber: 'Некорректное значение',
    },
  }),
});

// Define the rule globally
defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

defineRule('promoCodeLength', value => {
  return value.length>=11;
});

defineRule('vehiclePlateLength', value => {
  return value.length>=8 && value.length <=9;
});

defineRule('vehiclePlate', value => {
    return /^([ЕТУОРАНКХСВМ]{1}[\d]{3}[ЕТУОРАНКХСВМ]{2}[\d]{2,3})$/i.test(value);
});

defineRule('vehiclePlateNumber', value => {
    return /^([етуоранкхсвмЕТУОРАНКХСВМ]{1}[0-9]{3}[етуоранкхсвмЕТУОРАНКХСВМ]{2})$/.test(value);
});

defineRule('vehiclePlateRegion', value => {
    return /^([0-9]{2,3})$/.test(value);
});

defineRule('stsNumber', (value, {storedVal}) => {
  const val = storedVal || value;
  return /^([0-9]{2}\s?[а-яА-Я0-9]{2}\s?[0-9]{6})$/.test(val);
});

defineRule('eptsNumber', value => {
  return /^([0-9]{15})$/.test(value);
});


defineRule('stsSeriaCryllicOnly', value => {
  return new RegExp(/^[а-я\d\s]*$/i).test(value);
});

defineRule('stsDate',(value, {productionYear}) => {
  if (!productionYear) {
    return true;
  }
  return !(moment(value, FRONT_DATE_FORMAT).year() < parseInt(productionYear));
});

defineRule('markValidator', (value, {mapMarks}) => {
  return value && !!mapMarks[value];
});

defineRule('markModelValidator', (value, {mapModels, formData}) => {
  const savedModelId = formData && formData.car && formData.car.carModification.modelId;
  return value && !!mapModels[value] && mapModels[value].id === savedModelId;
});

defineRule('modificationValidator', (value, {mapModifications, formData}) => {
  const savedModificationId = formData && formData.car && formData.car.carModification.id;
  return value && !!mapModifications[value] && mapModifications[value].id === savedModificationId;
});

defineRule('productionYear', (value, {mapYears}) => {
  value = parseInt(value, 10);
  const validNumber = /^([0-9]){4}$/.test(value) && parseInt(value) > 1950 && parseInt(value) <= new Date().getFullYear();
  return validNumber && mapYears.find(item => parseInt(item, 10) === value);
});

defineRule('power', value => {
    return /^([0-9]{2,4}\.?[0-9]{0,2}|[0-9]{2,4})$/.test(value) && parseInt(value) > 50 && parseInt(value) < 1200;
});

defineRule('vin', value => {
    return /^([a-zA-Z0-9]){17}$/.test(value);
});

defineRule('bodyNumber', value => {
    let condition1 = /^([\sa-zA-ZА-Яа-я0-9\\-]){4,20}$/.test(value),
        // Номеров кузова без единой цифры не бывает (Боремся с номерами кузова "Нет" и "Отсутствует")
        condition2 = /[0-9]+/.test(value);
    if (!condition1) {
      return false;
    }

    return condition2;

});

defineRule('diagnosticCardNumber', value => {
    return /^([0-9]){15,21}$/.test(value);
});

defineRule('licenceNumber', value => {
    return /^([0-9]{2}\s?[0-9а-яА-Я]{2}\s?[0-9]{6})$/.test(value);
});

defineRule('personName', value => {
    return /^([а-яА-ЯёЁ-]{2,32})$/.test(value);
});

defineRule('passportNumber', value => {
    return /^([0-9]{4}\s?[0-9]{6})$/.test(value);
});

defineRule('phone', value => {
  const v = value.replace(/\s/g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "");
  return /^(8|\+7|7)[\d]{10}$/.test(v);
});

defineRule('date', value => {
    return moment(value, FRONT_DATE_FORMAT, true).isValid();
});

defineRule('datePrev', value => {
    return moment(value, FRONT_DATE_FORMAT, true).isValid()
        && moment(value, FRONT_DATE_FORMAT, true).isBefore();
});

defineRule('datePrevYear', value => {
    return Number(value) <= Number((new Date()).getFullYear());
});

defineRule('dateNext', value => {
  return moment(value, FRONT_DATE_FORMAT, true).isValid()
    && !(moment(value, FRONT_DATE_FORMAT).diff(moment()) < 0);
});

defineRule('diagnosticCardDate', value => {
  return moment(value, FRONT_DATE_FORMAT, true).isValid()
    && Number(moment(value, FRONT_DATE_FORMAT).toDate()) >= Number(moment("01.02.2021", FRONT_DATE_FORMAT).toDate())
});

defineRule('birthDate18', value => {
  const year = moment().diff(moment(value, FRONT_DATE_FORMAT), "year");
  return moment(value, FRONT_DATE_FORMAT, true).isValid() && year >= 18 && year <= 100;
});

defineRule('experienceStartDate', (value, {birthDate}) => {
  if (!birthDate) {
      return true;
  }

  const birth = moment(birthDate, FRONT_DATE_FORMAT, true)
  const exp = moment(value, FRONT_DATE_FORMAT, true);

  if (!birth.isValid() || !exp.isValid()) {
      return true;
  }
  return exp.isSameOrAfter(birth.add(16, 'year')) && exp.isSameOrBefore();
});

defineRule('experienceStartYear', (value, {birthDate}) => {
  if (!birthDate) {
      return true;
  }
  const birthYear = moment(birthDate, FRONT_DATE_FORMAT, true).year();
  return Number(value) - Number(birthYear) >= 16;
});

defineRule('passportIssueDate', (value, {birthDate}) => {
  if (!birthDate) {
      return true;
  }

  const birth =  moment(birthDate, FRONT_DATE_FORMAT, true);
  const issueDate = moment(value, FRONT_DATE_FORMAT);

  return birth.diff(issueDate, "year") <= -14
    && moment(value, FRONT_DATE_FORMAT, true).isBefore();
});

defineRule('duplicatelicenceNumber', (value, { allLicenceNumbers }) => {
  if (typeof allLicenceNumbers !== "object") {
      return true;
  }
  if (allLicenceNumbers.filter((number) => {
      return number.replace(/\s/g, "") === value.replace(/\s/g, "");
  }).length > 1) {
      return "Серия и номер прав не должны повторяться"
  } else {
      return true
  }
});

defineRule('registrationAddress', (value, { owner, mapCities }) => {
  const {
      city,
      cityUuid,
      cityCode,
      // street,
      // streetCode,
      houseNumber,
  } = owner;

  if (!Object.values(mapCities).some((city) => {
      return city.name === value
  })) {
      return 'Выберите значение из выпадающего списка';
  }

  if (!city) return 'Нужно указать населенный пункт';
  if (!cityUuid) return 'Нужно указать город';
  if (!cityCode) return 'Нужно указать город';
  // if (!street) return 'Нужно указать улицу';
  // if (!streetCode) return 'Нужно указать улицу';
  if (!houseNumber) return 'Нужно указать номер дома';

  return true
});

defineRule('startDate', (value, { target }) => {

  let status = true;
  let start = moment(value, FRONT_DATE_FORMAT);
  let valid = moment().add(1, "day");
  let card = target ? moment(target, FRONT_DATE_FORMAT) : null;
  let days = valid.diff(start, "days");

  if (days > 0) {
      this.message = `Дата должна быть не раньше ${valid.format(FRONT_DATE_FORMAT)}`;
      status = false;
  } else if (days < -270) {
      this.message = `Дата должна быть не позже ${valid.add(270, "day").format(FRONT_DATE_FORMAT)}`;
      status = false;
  } else if (card && card.diff(start, "days") <= 0) {
      this.message = `Ваша техосмотр действует до ${card.format(FRONT_DATE_FORMAT)}.
          Для оформления страховки с ${start.format(FRONT_DATE_FORMAT)} вам стоит оформить новый техосмотр.`;
      status = false
  }

  return status
});

defineRule('min2', (value) => {
  let status = true;
  if (value.length < 2) {
      status = false;
  }

  return status
});

defineRule('powerLessThan', (value, { target }) => {
  let status = true;
  if (value > target) {
      status = false;
  }

  return status
});
defineRule('driversExists', (value, { isUnlimitedDrivers, driverIsOwner, drivers}) => {
  return !!(isUnlimitedDrivers || !isUnlimitedDrivers && (drivers.length && drivers[0].licenceNumber && driverIsOwner || drivers.length>1));
});

defineRule('policyNumber', (value) => {
  return value.replace(" ", "").length === 13;
});
