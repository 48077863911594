const communications = {
  namespaced: true,
  state() {

  },
  actions: {
    sendEvent(context, msg) {
      const payload = {
        action: msg.action,
      };
      // TODO: заюзать webToNativeBridgeClient
      if (window.WebToNativeBridge && window.WebToNativeBridge.postMessage) {
        window.WebToNativeBridge.postMessage(msg.action);
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.WebToNativeBridge) {
        window.webkit.messageHandlers.WebToNativeBridge.postMessage(msg.action);
      } else {
        window.top.postMessage(payload, "*")
      }
    },
    sendLoadedEvent({ dispatch }) {
      dispatch('sendEvent', {action: 'event_loaded'});
    },
    async sendCloseEvent({ dispatch, commit }, options = {}) {
      commit('setGlobalLoading', true, {root: true});
      await dispatch("userData/sendGrowthbookEventClose", undefined, { root: true });
      const { route } = options;
      switch (route) {
        case 'osago':
          dispatch('sendEvent', {action: 'action_open_insurance', options: 'section=osago&param1=value1&param2=value2'});
          break;
        case 'finance':
          dispatch('sendEvent', {action: 'action_open_finance'});
          break;
        case 'fines':
          dispatch('sendEvent', {action: 'action_open_fines'});
          break;
        case 'taxes':
          dispatch('sendEvent', {action: 'action_open_taxes'});
          break;
        case 'main':
          dispatch('sendEvent', {action: 'action_open_main'});
          break;
        default:
          dispatch('sendEvent', {action: 'action_next_screen'});
      }
    },
    async sendCloseEventV2({ dispatch, commit }, options = {}) {
      commit('setGlobalLoading', true, {root: true});
      const { route } = options;
      switch (route) {
        case 'insurance':
          location.href="rosfines://app/insurance";
          break;
        case 'osago':
          let webQuery = [];
          if (options.source) {
            webQuery.push(`${encodeURIComponent('source')}=${encodeURIComponent(options.source)}`);
          }
          webQuery = encodeURIComponent(webQuery.join("&"));
          location.href=`rosfines://app/insurance?web_path=osago${(webQuery ? '&web_query=' + webQuery : '')}`;
          break;
        case 'finance':
          location.href="rosfines://app/finance";
          break;
        case 'fines':
          location.href="rosfines://app/finelist";
          break;
        case 'taxes':
          location.href="rosfines://app/taxlist";
          break;
        case 'main':
          location.href="rosfines://app/widgetlist";
          break;
        default:
          location.href="rosfines://action/close";
      }
    }
  },
};

export default communications;